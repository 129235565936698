<template>
	<span>
		<div v-if="!hideButton">
			<b-button v-if="tag === 'b-button'" :variant="variantBtn" @click="isOpen = true"> <i :class="icon" />&nbsp;&nbsp;{{ btnLabel }} </b-button>
			<b-dropdown-item v-if="tag === 'b-dropdown-item'" @click="isOpen = true"> <b-icon icon="envelope" />&nbsp;&nbsp;{{ btnLabel }} </b-dropdown-item>
		</div>

		<b-modal
			header-class="header-class-modal-doc-package"
			id="modal-subscription-solo"
			ref="modal-share-subscription-solo"
			v-model="isOpen"
			hide-footer
			hide-header
			no-close-on-backdrop
			no-close-on-esc
			size="md"
		>
			<div class="content-list-solo">
				<b-row>
					<b-col>
						<div style="font-size: 3rem; margin-left: 118px; margin-bottom: -47px">+</div>
						<p style="font-size: 3rem">{{ FormMSG(66, 'SOLO') }}</p>
						<p style="font-size: 2rem" v-html="FormMSG(96, '15€ / month')"></p>
						<p style="font-size: 1.3rem">
							<img src="../../assets/img/checkbox.jpeg" style="width: 30px; height: 30px" />
							{{ FormMSG(2, 'Unlimited projects') }}
						</p>
						<p style="font-size: 1.3rem">
							<img src="../../assets/img/checkbox.jpeg" style="width: 30px; height: 30px" />
							{{ FormMSG(3, 'Custom exports') }}
						</p>
						<p style="font-size: 1.3rem">
							<img src="../../assets/img/checkbox.jpeg" style="width: 30px; height: 30px" />
							{{ FormMSG(4, 'Invoice creation') }}
						</p>
						<p style="font-size: 1.3rem">
							<img src="../../assets/img/checkbox.jpeg" style="width: 30px; height: 30px" />
							{{ FormMSG(5, 'Unlimited archiving') }}
						</p>
						<p style="font-size: 1.3rem">
							<img src="../../assets/img/checkbox.jpeg" style="width: 30px; height: 30px" />
							{{ FormMSG(6, 'Support 24 / 7') }}
						</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<p style="font-size: 1.3rem" v-html="FormMSG(99, 'Click here to access Solo+ for free ')"></p>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col v-if="$screen.width <= 567" sm="12" md="6">
						<router-link v-if="!isPromotion" to="/settings" tag="button" class="subscription-dialog-button w-100 mb-3">
							{{ FormMSG(7, 'Subscribe') }}
						</router-link>
						<button v-if="isPromotion" class="subscription-dialog-button w-100 mb-3" @click="handleSubscribeClick">
							{{ FormMSG(7, 'Subscribe') }}
						</button>
					</b-col>
					<b-col sm="12" md="6">
						<button class="subscription-dialog-button w-100" size="sm" @click="isOpen = false">
							{{ FormMSG(8, 'Cancel') }}
						</button>
					</b-col>
					<b-col v-if="$screen.width > 567" sm="12" md="6">
						<router-link v-if="!isPromotion" to="/settings" tag="button" class="subscription-dialog-button w-100 mb-3">
							{{ FormMSG(7, 'Subscribe') }}
						</router-link>
						<button v-if="isPromotion" class="subscription-dialog-button w-100 mb-3" @click="handleSubscribeClick">
							{{ FormMSG(7, 'Subscribe') }}
						</button>
					</b-col>
				</b-row>
			</div>
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(12, 'Success!')"
			class="modal-success"
			v-model="showModalSuccessSubscribe"
			no-close-on-backdrop
			no-close-on-esc
			@ok="handleClickSuccessSubscribe"
			ok-variant="success"
			ok-only
		>
			{{ FormMSG(200, 'To finalize the activation of your Solo license, you must log in again') }}
		</b-modal>
	</span>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { updMyProject } from '@/cruds/project.crud';
import { store } from '@/store';
import moment from 'moment';

export default {
	name: 'SubscriptionDialog',

	mixins: [languageMessages],

	props: {
		variantBtn: { type: String, default: 'primary' },
		tag: { type: String, default: 'b-button' },
		icon: { type: String, default: 'fa fa-share' },
		btnLabel: { type: String, required: true },
		hideButton: { type: Boolean, required: false, default: false }
	},

	data() {
		return {
			isOpen: false,
			showModalSuccessSubscribe: false,
			isPromotion: false
		};
	},

	mounted() {
		setTimeout(() => {
			if (parseInt(process.env.VUE_APP_SOLOPLUS_FREE_LICENSE_DAYS) > 0) {
				this.isPromotion = true;
			} else {
				this.isPromotion = false;
			}
		}, 500);
	},

	methods: {
		handleClickSuccessSubscribe() {
			store.onLogout(this.$apollo.provider.defaultClient);
			this.$cookies.set('wizardAlreadyShow', false);
			this.$router.push('/pages/login');
			this.showModalSuccessSubscribe = false;
		},
		async handleSubscribeClick() {
			const startSoloPlusFreeLicense = new Date();
			const endSoloPlusFreeLicense = moment(new Date()).add(180, 'days');

			await updMyProject(store.state.myProject.id, {
				projectType: 4,
				startSoloPlusFreeLicense,
				endSoloPlusFreeLicense
			});
			this.isOpen = false;
			this.showModalSuccessSubscribe = true;
		},
		openModal() {
			this.isOpen = true;
		}
	}
};
</script>

<style>
#modal-subscription-solo .modal-content {
	background: transparent !important;
	border: none !important;
}
#modal-subscription-solo .modal-header {
	border: none;
}
#modal-subscription-solo .content-list-solo {
	background-color: #02b49e;
	border-radius: 10px;
	padding: 0px 35px 35px 35px;
	color: white;
}
#modal-subscription-solo .subscription-dialog-button {
	border: 2px solid white;
	background-color: #02b49e;
	width: 100%;
	color: white;
	font-size: 1.8rem;
	border-radius: 5px;
	cursor: pointer;
}
#modal-subscription-solo .subscription-dialog-button:hover {
	background-color: #048578;
}
#modal-subscription-solo .close {
	display: none;
}
</style>
